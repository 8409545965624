import cn from 'classnames';
import { HubModule, PropsWithTestId } from '../../../../../../types';
import { HubModuleTypes } from '../../../../../../constants';
import s from './RichTextModule.module.scss';
import { HubModuleWrapper } from '../../HubModuleWrapper';
import { Paragraph } from '../../components';

export interface RichTextModuleType extends HubModule {
  '@type': HubModuleTypes.RichText;
  richText: string;
}

export interface RichTextModuleProps {
  module: RichTextModuleType;
}

export default function RichTextModule({
  module: { '@type': type, richText, elementId, visualStyle },
  testId,
}: PropsWithTestId<RichTextModuleProps>) {
  return (
    <HubModuleWrapper
      type={type}
      elementId={elementId}
      testId={testId}
      theme={visualStyle}
    >
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <Paragraph
              text={richText}
              className={cn(s.text, 'formatted-text')}
            />
          </div>
        </div>
      </div>
    </HubModuleWrapper>
  );
}

RichTextModule.defaultProps = {
  testId: 'hub-rich-text-module',
};
